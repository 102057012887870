<template>
  <div class="component video-thumbnail" v-bind:class="{ display }"
       v-on:click="onClick">
    <div class="status pa-1">
      <v-icon small color="green" v-if="seen">mdi-eye</v-icon>
      <v-icon small color="green" v-if="completed">mdi-check</v-icon>
    </div>
    <div class="d-flex">
      <div>
        <v-img
            lazy-src="https://picsum.photos/id/11/10/6"
            v-bind:src="`https://i.ytimg.com/vi/${video.videoId}/mqdefault.jpg`"
            aspect-ratio="1.7778"
            v-bind:style="{'width': imageWidth}"
        ></v-img>
      </div>
      <div class="flex-grow-1">
        <div class="video-meta">
          <span class="video-title">{{ video.title }}</span>
          <span class="video-author text--secondary">
            {{ video.videoOwnerChannelTitle }}
          </span>
          <span class="video-publish-date text--secondary">{{ publishDateDuration.humanize() }} ago</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import moment from "moment";
import {mapGetters} from "vuex";

export default {
  name: 'components-video_list-video-index',
  watch: {},
  props: {
    display: undefined,
    video: {
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  },
  computed: {
    imageWidth() {
      switch(this.display) {
        case 'mobile':
          return '100px';
        default:
          return '200px';
      }
    },
    seen() {
      return this.seenVideos.indexOf(this.video.videoId) > -1;
    },
    completed() {
      return this.completedVideos.indexOf(this.video.videoId) > -1;
    },
    publishDate() {
      return moment(this.video.publishedAt).diff(moment());
    },
    publishDateDuration() {
      return moment.duration(moment(this.video.publishedAt).diff(moment()));
    },
    ...mapGetters([
      'seenVideos',
      'completedVideos'
    ])
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.video-thumbnail {
  cursor: pointer;
  position: relative;

  &.focus {
    //border: 1px solid #999;
  }

  &.horizontal {
    display: inline-block;
  }

  .status {
    position: absolute;
    bottom: 0;
    right: 0;
  }

}

.video-meta {
  padding: .25em .5em .25em .5em;
  white-space: normal;

  .video-title {
    font-size: .9em;
    font-weight: bold;
    line-height: 1.2em;
    display: block;
  }

  .video-publish-date,
  .video-author {
    font-size: .8em;
    display: block;
  }
}
</style>
