<template>
  <div class="component h-100 view" v-bind:class="[displayModeCalculated]">
    <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__faster animate__fadeIn transition-speed"
        leave-active-class="animate__animated animate__fadeOut transition-speed"
        v-on:after-leave="spinnerTransitionAfter"
    >
      <div class="d-flex justify-center align-center h-100 spinner-container" v-if="isLoading">
        <div class="text-center">
          <v-progress-circular color="lime" indeterminate size="70"></v-progress-circular>
          <div class="mt-3 text-uppercase">
            <v-chip color="indigo darken-2">
              Retrieving Playlist
            </v-chip>
          </div>
        </div>
      </div>
    </transition>

    <template v-if="playlistError">
      <div class="d-flex justify-center align-center h-100">
        <div class="text-center">
          <div>
            {{ playlistError }}
          </div>
          <v-btn v-bind:to="{'name': 'home'}">Back</v-btn>
        </div>
      </div>
    </template>

    <transition
        v-if="!playlistError"
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__faster animate__fadeIn transition-speed"
        leave-active-class="animate__animated animate__faster animate__fadeOut transition-speed"
    >
      <div v-if="loadingAnimationDone" class="h-100 d-flex flex-column">
        <div>
          <v-card color="primary darken-3">
            <v-card-text v-bind:class="{'pa-0': displayModeCalculated === DISPLAY_MODES.MOBILE}">
              <div class="d-flex">
                <div class="flex-grow-1 d-flex align-center">
                  <playlist-header
                      v-bind:cache-update-at="cacheUpdatedAt"
                      v-bind:playlist="playlist"></playlist-header>
                </div>
                <div v-if="displayModeCalculated !== DISPLAY_MODES.MOBILE" class="d-flex align-center">
                  <playlist-controls
                      v-bind:previous-video="previousVideo"
                      v-bind:next-video="nextVideo"
                      v-bind:sort-mode="sortMode"
                      v-bind:sort-direction="sortDirection"
                      v-on:setSortDirection="setSortDirection"
                      v-on:setSort="setSort"
                      v-on:playPrevious="playPrevious"
                      v-on:playNext="playNext"
                  />
                </div>
                <div class="pl-2">
                  <user-bar />
                </div>
                <div>
                  <v-dialog
                      max-width="600"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-bind="attrs"
                          v-on="on"
                          x-small
                          class="ma-0 ml-2"
                          fab depressed
                          color="indigo darken-3">
                        <v-icon
                            color="indigo lighten-4">mdi-help</v-icon>
                      </v-btn>
                    </template>

                    <v-card min-height="500">
                      <feedback-form />
                    </v-card>
                  </v-dialog>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>

        <div class="flex-grow-1 main-container">
          <div class="d-flex h-100">
            <div class="flex-grow-1">
              <template v-if="!currentVideoId">
                Video not found
              </template>
              <template v-else-if="currentVideo">
                <div v-bind:class="{'ma-2': displayModeCalculated === DISPLAY_MODES.NORMAL}">
                  <div class="youtube-player-container">
                    <youtube-player
                        v-on:stopped="onVideoStopped"
                        v-bind:video-id="currentVideoId"></youtube-player>
                  </div>

                  <div class="player-controls-container">
                    <player-controls
                        v-bind:previous-video="previousVideo"
                        v-bind:next-video="nextVideo"
                        v-on:play-previous="playPrevious"
                        v-on:play-next="playNext"
                        v-on:toggle-display="toggleDisplay"
                        v-bind:allow-mode-toggle="displayModeCalculated !== DISPLAY_MODES.MOBILE"
                        v-bind:current-mode="displayModeCalculated"
                        v-on:mark-completed="markCompletedAndForward"
                    />
                  </div>

                  <div class="video-details-container">
                    <video-details v-bind:video="currentVideo"></video-details>
                  </div>
                </div>

              </template>
            </div>
            <div class="video-list-container" v-if="displayModeCalculated === DISPLAY_MODES.NORMAL">
              <div class="d-flex flex-column h-100">
                <div>
                </div>
                <div class="flex-grow-1" style="position: relative;">
                  <video-list class="video-list"
                              v-bind:videos="sortedVideos"
                              v-bind:focus-video="currentVideoId"
                              v-on:clicked-thumbnail="onClickedThumbnail"
                              v-bind:loading="isLoading"></video-list>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-container">
          <template v-if="displayModeCalculated === DISPLAY_MODES.MOBILE">
            <div class="d-flex flex-column h-100">
              <div class="flex-grow-1" style="position: relative;">
                <video-list class="video-list"
                            v-bind:videos="sortedVideos"
                            v-bind:focus-video="currentVideoId"
                            v-on:clicked-thumbnail="onClickedThumbnail"
                            v-bind:loading="isLoading"></video-list>
              </div>
              <div>
                <playlist-controls
                    v-bind:previous-video="previousVideo"
                    v-bind:next-video="nextVideo"
                    v-bind:sort-mode="sortMode"
                    v-bind:sort-direction="sortDirection"
                    v-on:setSortDirection="setSortDirection"
                    v-on:setSort="setSort"
                    v-on:playPrevious="playPrevious"
                    v-on:playNext="playNext"
                />
              </div>
            </div>
          </template>
          <div v-if="displayModeCalculated === DISPLAY_MODES.CINEMA">
            <div style="position: relative;">
              <video-list class="video-list horizontal"
                          v-bind:videos="sortedVideos"
                          v-bind:focus-video="currentVideoId"
                          v-on:clicked-thumbnail="onClickedThumbnail"
                          v-bind:loading="isLoading"></video-list>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script type="text/babel">
const lzstring = require('lz-string');

import {mapGetters, mapMutations} from 'vuex'

import YoutubePlayer from '@/components/youtube-player'
import VideoList from '@/components/video-list'
import VideoDetails from '@/components/video-details'
import PlaylistControls from '@/components/playlist-controls'
import PlaylistHeader from '@/components/playlist-header'
import PlayerControls from '@/components/player-controls'
import UserBar from '@/components/user-bar'

import _findIndex from 'lodash/findIndex'
import _find from 'lodash/find'
import _get from 'lodash/get'
import moment from 'moment'

import SORTS_DIRECTION from "@/constants/SortDirections";
import SORTS from "@/constants/SortModes";
import DISPLAY_MODES from '@/constants/DisplayModes'

import ApiClient from '@/clients/api'
import FeedbackForm from "@/components/feedback-form";

export default {
  name: 'views-playlist-index',
  watch: {
    sortMode() {
      this.savePlaylistHistorySettings();
    },
    sortDirection() {
      this.savePlaylistHistorySettings();
    },
    currentVideo(newValue) {
      this.setTitle();

      if (newValue) {
        this.addSeenVideo(newValue.videoId);
        this.savePlaylistHistorySettings();
      }
    },
    playlist() {
      this.setTitle();
    }
  },
  props: {},
  data() {
    return {
      DISPLAY_MODES,
      SORTS,
      SORTS_DIRECTION,
      playlistError: undefined,
      loadingAnimationDone: false,
      initialized: false,
      isLoading: false,
      videos: [],
      playlist: undefined,
      cacheUpdatedAt: undefined
    }
  },
  methods: {
    savePlaylistHistorySettings() {
      let settings = {
        lastVideo: this.currentVideoId,
        sortMode: this.sortMode,
        sortDirection: this.sortDirection
      };

      this.setPlaylistHistorySettings({
        playlistId: this.currentPlaylistId,
        settings
      });
    },
    markCompletedAndForward() {
      this.addCompletedVideos(this.currentVideoId);
      if (this.nextVideo) {
        this.playNext();
      }
    },
    setTitle() {
      let parts = [
        // 'Playlist.Tools',
      ];

      if (this.playlist) {
        parts.push(this.playlist.snippet.title);
      }

      if (this.currentVideo) {
        parts.push(this.currentVideo.title);
      }

      document.title = parts.join(' - ');
    },
    spinnerTransitionAfter() {
      this.loadingAnimationDone = true;
    },
    focusOnCurrent() {

    },
    onVideoStopped() {
      this.addCompletedVideos(this.currentVideoId);
      if (this.nextVideo) {
        this.playNext();
      }
    },
    setSortDirection(sortDirection) {
      let query = {
        ...this.$route.query,
        sortDirection: sortDirection
      };

      this.$router.push({
        ...this.$route,
        query
      });
    },
    setSort(sort) {
      let query = {
        ...this.$route.query,
        sortMode: sort
      };

      this.$router.push({
        ...this.$route,
        query
      });
    },
    playNext() {
      this.navigateToVideo(this.nextVideo);
    },
    playPrevious() {
      this.navigateToVideo(this.previousVideo);
    },
    onClickedThumbnail(video) {
      this.navigateToVideo(video);
    },
    navigateToVideo(video) {
      this.$router.push({
        name: 'playlist',
        params: {
          playlist: this.currentPlaylistId,
          videoId: video.videoId
        },
        query: this.$route.query
      })
    },
    retrieve() {
      this.isLoading = true;

      return ApiClient.getPlaylist(this.currentPlaylistId)
          .then((res) => {
            this.videos = JSON.parse(lzstring.decompress(res.data.items));
            this.playlist = res.data.playlist;
            this.cacheUpdatedAt = res.data.cacheUpdatedAt;
            this.addPlaylistHistory(this.playlist);
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    toggleDisplay() {
      console.log('toggleDisplay');
      this.setDisplayMode(this.displayMode === DISPLAY_MODES.NORMAL ? DISPLAY_MODES.CINEMA : DISPLAY_MODES.NORMAL);
    },
    ...mapMutations([
      'setPlaylistHistorySettings',
      'setDisplayMode',
      'addSeenVideo',
      'addCompletedVideos',
      'addPlaylistHistory'
    ])
  },
  computed: {
    displayModeCalculated() {
      if(['xs', 'sm'].indexOf(this.$vuetify.breakpoint.name) > -1) {
        return DISPLAY_MODES.MOBILE;
      }

      return this.displayMode;
    },
    sortMode() {
      return this.$route.query.sortMode ? this.$route.query.sortMode : SORTS.NONE;
    },
    sortDirection() {
      return this.$route.query.sortDirection ? this.$route.query.sortDirection : SORTS_DIRECTION.DESCENDING;
    },
    sortedVideos() {
      let videos = [...this.videos];

      switch (this.sortMode) {
        case SORTS.CHRONOLOGICAL:
          videos = videos.sort((a, b) => {
            if (moment(a.publishedAt).isAfter(b.publishedAt)) {
              return 1;
            } else if (moment(a.publishedAt).isBefore(b.publishedAt)) {
              return -1
            } else {
              return 0;
            }
          });
          break;
        case SORTS.TITLE:
          videos = videos.sort((a, b) => {
            if (a.title > b.title) {
              return 1
            } else if (a.title === b.title) {
              return 0;
            } else {
              return -1;
            }
          });
          break;
      }

      if (this.sortDirection === SORTS_DIRECTION.DESCENDING) {
        videos = videos.reverse();
      }

      return videos;
    },
    currentPlaylistId() {
      return this.$route.params.playlistId;
    },
    currentVideoId() {
      return this.$route.params.videoId;
    },
    previousVideo() {
      if (this.currentVideoIndex > 0) {
        return this.sortedVideos[this.currentVideoIndex - 1];
      }
    },
    nextVideo() {
      if (this.sortedVideos.length > this.currentVideoIndex + 1) {
        return this.sortedVideos[this.currentVideoIndex + 1];
      }
    },
    currentVideoIndex() {
      return _findIndex(this.sortedVideos, (video) => {
        return video.videoId === this.currentVideoId;
      });
    },
    currentVideo() {
      return this.sortedVideos.filter((video) => {
        return video.videoId === this.currentVideoId;
      }).pop();
    },
    currentPlaylistHistory() {
      return _find(this.playlistHistory, (playlist) => {
        return playlist.id === this.currentPlaylistId;
      });
    },
    ...mapGetters([
      'displayMode',
      'playlistHistory'
    ])
  },
  created() {
    if (!this.currentPlaylistHistory) {
      return;
    }

    let hasChanges = false;

    let location = {
      name: 'playlist',
      params: {
        playlistId: this.currentPlaylistId
      },
      query: {}
    };

    let lastVideo = _get(this.currentPlaylistHistory, '_settings.lastVideo');
    if (lastVideo && lastVideo !== this.currentVideoId) {
      location.params = {
        ...location.params,
        videoId: lastVideo
      }
      hasChanges = true;
    }

    let sortDirection = _get(this.currentPlaylistHistory, '_settings.sortDirection');
    if (sortDirection && sortDirection !== this.sortDirection) {
      location.query = {
        ...location.query,
        sortDirection
      }
      hasChanges = true;
    }

    let sortMode = _get(this.currentPlaylistHistory, '_settings.sortMode');
    if (sortMode && sortMode !== this.sortMode) {
      location.query = {
        ...location.query,
        sortMode
      }
      hasChanges = true;
    }

    location.query = {
      ...this.$route.query,
      ...location.query
    }

    location.params = {
      ...this.$route.params,
      ...location.params
    }

    if (hasChanges) {
      this.$router.replace(location)
    }
  },
  mounted() {
    this.retrieve()
        .then(() => {
          if (!this.currentVideoId) {
            let firstVideo = this.sortedVideos[0];
            this.$router.replace({
              name: 'playlist',
              params: {
                playlist: this.playlistId,
                videoId: firstVideo.videoId
              }
            })
          }
        })
        .catch((e) => {
          let error = e;
          if (e.response && e.response.data) {
            error = e.response.data.error;
          }

          this.playlistError = error;
        })
        .finally(() => {
          this.initialized = true;
        });
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {
    FeedbackForm,
    UserBar,
    PlayerControls,
    PlaylistHeader,
    PlaylistControls,
    YoutubePlayer,
    VideoDetails,
    VideoList
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.view {
  .transition-speed {
    --animate-duration: 0.25s;
  }

  .youtube-player-container {
  }

  .video-details-container {

  }

  .video-list-container {
  }

  &.NORMAL {
    .video-list-container {
      position: relative;
      min-width: 400px;
    }

    .video-list {
      overflow-y: auto;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &.MOBILE {
    .main-container {
      flex-grow: 0 !important;
    }

    .bottom-container {
      flex-grow: 1 !important;

      .video-list {
        overflow-y: auto;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  &.CINEMA {
    .bottom-container {
      overflow: auto;
    }

    .video-list {
      overflow-x: auto;
      white-space: nowrap;
    }
  }
}
</style>
