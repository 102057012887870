import Vue from 'vue'
import Vuex from 'vuex'
import store from 'store'
import DisplayModes from '@/constants/DisplayModes'
import _findIndex from 'lodash/findIndex'
import _get from 'lodash/get'
import RealtimeDatabase from '@/services/realtimeDatabase'
import firebase from 'firebase/app';

import placeholder from "lodash/fp/placeholder";

Vue.use(Vuex)

const StoreKey = 'STATE';

function convertStateFromLocalStorage(realtimeState) {
    let localState = store.get(StoreKey);
    if (!localState) {
        console.log('nothing to merge');
        return Promise.resolve();
    }

    let mergedState = Object.assign({}, JSON.parse(JSON.stringify(realtimeState)));

    _get(localState, 'completedVideos', []).forEach((completedVideo) => {
        if (mergedState.completedVideos.indexOf(completedVideo) === -1) {
            mergedState.completedVideos.push(mergedState);
        }
    });
    _get(localState, 'seenVideos', []).forEach((seenVideo) => {
        if (mergedState.seenVideos.indexOf(seenVideo) === -1) {
            mergedState.seenVideos.push(mergedState);
        }
    });

    let existingPlaylistHistoryIds = _get(mergedState, 'playlistHistory', []).map((playlist) => {
        return playlist.id;
    })
    let newPlaylists = [];
    _get(localState, 'playlistHistory', []).forEach((playlist) => {
        newPlaylists.push(playlist);
    });

    let promises = [];

    newPlaylists.forEach((playlist) => {
        promises.push(RealtimeDatabase.savePlaylistHistory(playlist));
    });

    console.log('new playlists', newPlaylists);

    // console.log('mergedState', realtimeState.playlistHistory.length, localState.playlistHistory.length, mergedState.playlistHistory.length);

    return Promise.resolve()
        .then(() => {
            return saveState(mergedState);
        })
        .then(() => {
            return promises;
        })
        .then(() => {
            return mergedState;
        })
}

function savePlaylistHistory(playlist) {
    if (STORE.state.user) {
        console.log('Store playlist realtime');
        RealtimeDatabase.savePlaylistHistory(playlist);
    } else {
        console.log('Store playlist local');
        let currentState = store.get(StoreKey);
        if (!currentState) {
            currentState = {};
        }

        if (!currentState.playlistHistory) {
            currentState.playlistHistory = [];
        }
        currentState.playlistHistory.push(playlist);
        store.set(StoreKey, currentState);
    }

    return Promise.resolve();
}

function saveState(state) {
    let strippedState = Object.assign({}, state);
    // delete strippedState.user;

    strippedState.user = firebase.auth().currentUser;
    delete strippedState.version;
    delete strippedState.determineAuthPromise;
    delete strippedState.determinedAuth;
    delete strippedState.playlistHistory;

    if (STORE.state.user) {
        console.log('Store state realtime');
        RealtimeDatabase.saveUserState(strippedState);
    } else {
        console.log('Store state local');
        store.set(StoreKey, strippedState);
    }

    return Promise.resolve();
}

function getState() {
    if (STORE.state.user) {
        let realtimeState;
        return RealtimeDatabase.getUserState()
            .then((res) => {
                realtimeState = res.val();
            })
            .then(() => {
                return realtimeState;
            })
    } else {
        return Promise.resolve(store.get(StoreKey));
    }
}

const STORE = new Vuex.Store({
    state: {
        user: undefined,
        determineAuthPromise: undefined,
        determinedAuth: false,

        version: undefined,

        displayMode: DisplayModes.NORMAL,
        playlistHistory: [],
        seenVideos: [],
        completedVideos: []
    },
    mutations: {
        setState(state, override) {
            this.state = Object.assign(state, override);
            console.log('set state:', this.state);
        },
        setVersion(state, version) {
            state.version = version;
        },
        setDetermineAuthPromise(state, promise) {
            state.determineAuthPromise = promise;
        },
        setDeterminedAuth(state) {
            state.determinedAuth = true;
        },
        setUser(state, user) {
            console.log('set user', user);
            state.user = user;
        },
        clearUser(state) {
            state.user = undefined;
        },

        setDisplayMode(state, mode) {
            state.displayMode = mode;
            saveState(state);
        },
        addCompletedVideos(state, videoId) {
            if (state.completedVideos.indexOf(videoId) === -1) {
                state.completedVideos.push(videoId);
            }

            saveState(state);
        },
        removeCompletedVideos(state, videoId) {
            const index = state.completedVideos.indexOf(videoId);
            if (index > -1) {
                state.completedVideos.splice(index, 1);
            }

            saveState(state);
        },
        addSeenVideo(state, videoId) {
            if (state.seenVideos.indexOf(videoId) === -1) {
                state.seenVideos.push(videoId);
            }

            saveState(state);
        },
        removeSeenVideo(state, videoId) {
            const index = state.seenVideos.indexOf(videoId);
            if (index > -1) {
                state.seenVideos.splice(index, 1);
            }

            saveState(state);
        },
        addPlaylistHistory(state, playlist) {
            state.playlistHistory = state.playlistHistory.filter((item) => {
                return item.id !== playlist.id;
            });

            state.playlistHistory.push(playlist);

            saveState(playlist);
        },
        setPlaylistHistorySettings(state, {playlistId, settings}) {
            let index = _findIndex(state.playlistHistory, (playlist) => {
                return playlistId === playlist.id;
            });

            if (index === -1) {
                return;
            }

            let currentSettings = _get(state, `playlistHistory.${index}._settings`);

            let mergedSettings = {
                ...state.playlistHistory[index]._settings,
                ...settings
            };
            state.playlistHistory[index]._settings = mergedSettings;

            saveState(state);
        }
    },
    getters: {
        version(state) {
            return state.version;
        },
        determineAuthPromise(state) {
            return state.determineAuthPromise;
        },
        determinedAuth(state) {
            return state.determinedAuth;
        },
        user(state) {
            return state.user;
        },

        displayMode(state) {
            return state.displayMode;
        },
        completedVideos(state) {
            return state.completedVideos;
        },
        seenVideos(state) {
            return state.seenVideos;
        },
        playlistHistory(state) {
            return state.playlistHistory;
        }
    },
    actions: {
        saveState(context) {
            return saveState(context.state);
        },
        convertStateFromLocalStorage(context) {
            return convertStateFromLocalStorage(context.state);
        },
        restoreState(context) {
            return getState()
                .then((state) => {
                    let strippedState = Object.assign({}, state);
                    delete strippedState.user;
                    context.commit('setState', strippedState);
                })
        }
    },
    modules: {}
})

export default STORE;