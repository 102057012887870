import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/database'
import 'firebase/analytics'

import config from './config'

// Initialize Firebase
firebase.initializeApp({
    apiKey: config.firebase.apiKey,
    authDomain: config.firebase.authDomain,
    projectId: config.firebase.projectId,
    storageBucket: config.firebase.storageBucket,
    messagingSenderId: config.firebase.messagingSenderId,
    databaseURL: config.firebase.databaseURL,
    appId: config.firebase.appId
});

firebase.analytics();

store.commit('setDetermineAuthPromise', new Promise(resolve => {
    firebase.auth().onAuthStateChanged((user) => {
        console.log('auth state change', user);
        if (user) {
            store.commit('setUser', user);
        } else {
            store.commit('clearUser');
        }

        store.dispatch('restoreState')
        store.commit('setDeterminedAuth');

        resolve();
    });
}));

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
