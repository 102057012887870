import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/redesign'
import Login from '@/views/login'
import Playlist from '@/views/playlist/redesign'
import Admin from '@/views/admin/'
import Test from '@/views/test'

import firebase from 'firebase/app';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/test',
        name: 'test',
        component: Test
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/logout',
        name: 'logout',
        component: Login
    },
    {
        path: '/admin',
        name: 'admin',
        component: Admin
    },
    {
        path: '/:playlistId/:videoId?',
        name: 'playlist',
        component: Playlist
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.afterEach((to, from) => {
    if (PRODUCTION) {
        firebase.analytics().setCurrentScreen(window.location.pathname) // sets `screen_name` parameter
        firebase.analytics().logEvent('screen_view')
        console.log('Recorded view', window.location.pathname)
    }
})

export default router
