<template>
  <v-app>
    <v-main>
      <div class="settings" v-if="false">
        <div class="d-flex align-content-end">
          <v-btn v-bind:light="!this.$vuetify.theme.dark"
                 v-on:click="toggleDarkMode"
          >
            <v-icon>mdi-theme-light-dark</v-icon>
          </v-btn>
        </div>
      </div>

      <template v-if="!determinedAuth">
        <div class="d-flex h-100 justify-content-center align-items-center">
          <div>
            <v-progress-circular indeterminate size="70"/>
          </div>
        </div>
      </template>
      <template v-else>
        <router-view/>
      </template>

      <v-snackbar v-model="showNewVersionSnackbar"
      v-bind:timeout="-1">
        A new version is available!
        <template v-slot:action="{ attrs }">
          <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="refreshPage"
          >
            Reload
          </v-btn>
        </template>
      </v-snackbar>

      <div class="version">
        <v-dialog
            max-width="600"
        >
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on">
              {{ version }}
            </span>
          </template>

          <v-card min-height="500">
            <v-card-title>
              <h2>Change log</h2>
            </v-card-title>
            <v-card-text>
              <changelog />
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <v-snackbar
          v-bind:timeout="5000"
          v-model="snackbar"
      >
        <div class="d-flex">
          <div>
            Liking playlist.tools? We would really appreciate your feedback :)
          </div>
          <div>
            <v-dialog
                max-width="600"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    class="ma-0 ml-2"
                    depressed
                    color="indigo darken-3">
                  Leave Feedback
                </v-btn>
              </template>

              <v-card min-height="500">
                <feedback-form />
              </v-card>
            </v-dialog>
          </div>
        </div>

      </v-snackbar>

    </v-main>
  </v-app>
</template>

<script type="text/babel">
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import FeedbackForm from "@/components/feedback-form";
import Changelog from "@/components/changelog";

export default {
  name: 'components-_boilerplate-index',
  watch: {},
  props: {},
  data() {
    return {
      snackbar: true,
      showNewVersionSnackbar: false
    }
  },
  methods: {
    refreshPage() {
      window.location.reload();
    },
    determineVersion() {
      return axios.get(`/version.txt?rand=${(new Date()).getTime()}`)
          .then((res) => {
            console.log('determine version');

            if (this.version && this.version !== res.data) {
              this.showNewVersionSnackbar = true;
              console.log('version mismatch');
            } else {
              this.$store.commit('setVersion', res.data);

              setTimeout(() => {
                this.determineVersion();
              }, 30000);
            }
          })
    },
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    ...mapActions(['restoreState'])
  },
  computed: {
    ...mapGetters(['determinedAuth', 'determineAuthPromise', 'version'])
  },
  created() {
    if (this.determineAuthPromise) {
      this.determineAuthPromise
          .then(() => {
            return this.restoreState();
          });
    }

    this.determineVersion();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {
    Changelog,
    FeedbackForm
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>

<style scoped lang="scss">
.v-application {
  background-color: var(--v-background-base) !important;
}

.version {
  position: fixed;
  bottom: 0;
  right: 0;
  text-transform: uppercase;
  font-size: .75em;
  color: #999;
  padding: 5px;
}

.settings {

}
</style>