<template>
  <div class="component">
    <ul>
      <li>
        2022-09-21
        <ul>
          <li>
            Theme changes
          </li>
          <li>
            Improving load speed by adding compression
          </li>
        </ul>
      </li>
      <li>
        2022-09-18
        <ul>
          <li>
            Resizing payloads
          </li>
          <li>
            Enabling channel uploads
          </li>
        </ul>
      </li>
      <li>
        2022-09-13
        <ul>
          <li>
            Adding site description and changelog.
          </li>
        </ul>
      </li>
      <li>
        2022-09-10
        <ul>
          <li>
            By popular request: Playlists will not auto refresh when retrieved after 2 days or more. Frequency
            restriction exists to stay within YouTube API limits.
          </li>
        </ul>
      </li>
      <li>
        2023-02-20
        <ul>
          <li>
            Search by playlist name
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script type="text/babel">
export default {
  name: 'components-changelog-index',
  watch: {},
  props: {},
  data() {
    return {}
  },
  methods: {},
  computed: {},
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
