var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component"},[(_vm.playlists)?[_c('div',{staticClass:"d-flex"},[_c('div',[_c('v-select',{attrs:{"hide-details":"","dense":"","outlined":"","items":_vm.playlistSortItems},model:{value:(_vm.playlistsSortMode),callback:function ($$v) {_vm.playlistsSortMode=$$v},expression:"playlistsSortMode"}})],1),_c('div',[_c('v-btn',{on:{"click":_vm.toggleOrder}},[_c('v-icon',[_vm._v(_vm._s(_vm.playlistsSort === _vm.SORT_DIRECTIONS.ASCENDING ? 'mdi-sort-descending' : 'mdi-sort-ascending')+" ")])],1)],1)]),_c('div',{staticClass:"pa-md-3"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableItems,"options":{
              itemsPerPage: 50
            }},scopedSlots:_vm._u([{key:"item.thumbnail",fn:function(ref){
            var item = ref.item;
return [_c('v-img',{attrs:{"width":"100","src":item.thumbnail.url}})]}},{key:"item.playlist.snippet.title",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{attrs:{"to":{
              name: 'playlist',
              params: {
                playlistId: item.playlist.id
              }
            }}},[_vm._v(_vm._s(item.playlist.snippet.title))])]}}],null,false,2780623985)})],1)]:(_vm.loading)?[_c('div',{staticClass:"h-100 d-flex justify-center align-center"},[_c('div',{staticClass:"h-100"},[_c('v-progress-circular',{attrs:{"size":"70","indeterminate":""}})],1)])]:void 0],2)}
var staticRenderFns = []

export { render, staticRenderFns }