<template>
  <div class="component">
    <v-card color="primary darken-2" elevation="0">
      <v-card-text>
        <template v-if="isRetrieving">
          <v-progress-circular indeterminate />
        </template>
        <template v-else-if="details">
          <h5>{{ details.snippet.title }}</h5>
          <span class="d-block" v-html="details.snippet.description"></span>
          <span class="text--secondary">{{ publishDate.format('MMM DD, YYYY') }}</span>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script type="text/babel">
import moment from 'moment'
import ApiClient from "@/clients/api";

export default {
  name: 'components-video_details-index',
  watch: {
    video() {
      this.retrieveVideoDetails();
    }
  },
  props: {
    video: {
      required: true
    }
  },
  data() {
    return {
      isRetrieving: false,
      details: undefined
    }
  },
  methods: {
    retrieveVideoDetails() {
      this.isRetrieving = true;
      return ApiClient.getCacheDetails(this.video.cacheItemsPath, this.video.itemsIndex)
          .then((result) => {
            this.details = result.data;
          })
          .finally(() => {
            this.isRetrieving = false;
          })
    }
  },
  computed: {
    publishDate() {
      return moment(this.details.snippet.publishedAt);
    }
  },
  created() {
  },
  mounted() {
    this.retrieveVideoDetails();
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
