var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',[(false)?_c('div',{staticClass:"settings"},[_c('div',{staticClass:"d-flex align-content-end"},[_c('v-btn',{attrs:{"light":!this.$vuetify.theme.dark},on:{"click":_vm.toggleDarkMode}},[_c('v-icon',[_vm._v("mdi-theme-light-dark")])],1)],1)]):_vm._e(),(!_vm.determinedAuth)?[_c('div',{staticClass:"d-flex h-100 justify-content-center align-items-center"},[_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"70"}})],1)])]:[_c('router-view')],_c('v-snackbar',{attrs:{"timeout":-1},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":_vm.refreshPage}},'v-btn',attrs,false),[_vm._v(" Reload ")])]}}]),model:{value:(_vm.showNewVersionSnackbar),callback:function ($$v) {_vm.showNewVersionSnackbar=$$v},expression:"showNewVersionSnackbar"}},[_vm._v(" A new version is available! ")]),_c('div',{staticClass:"version"},[_c('v-dialog',{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.version)+" ")])]}}])},[_c('v-card',{attrs:{"min-height":"500"}},[_c('v-card-title',[_c('h2',[_vm._v("Change log")])]),_c('v-card-text',[_c('changelog')],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":5000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v(" Liking playlist.tools? We would really appreciate your feedback :) ")]),_c('div',[_c('v-dialog',{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 ml-2",attrs:{"small":"","depressed":"","color":"indigo darken-3"}},'v-btn',attrs,false),on),[_vm._v(" Leave Feedback ")])]}}])},[_c('v-card',{attrs:{"min-height":"500"}},[_c('feedback-form')],1)],1)],1)])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }