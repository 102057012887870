var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-grow-1"},[(_vm.allowModeToggle)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"tile":""},on:{"click":_vm.toggleDisplayMode}},on),[(_vm.currentMode === _vm.DISPLAY_MODES.NORMAL)?_c('v-icon',[_vm._v("mdi-arrow-expand-horizontal")]):_vm._e(),(_vm.currentMode === _vm.DISPLAY_MODES.CINEMA)?_c('v-icon',[_vm._v("mdi-arrow-collapse-horizontal")]):_vm._e()],1)]}}],null,false,1122362311)},[_c('span',[(_vm.currentMode === _vm.DISPLAY_MODES.NORMAL)?[_vm._v(" Switch to Cinema mode ")]:_vm._e(),(_vm.currentMode === _vm.DISPLAY_MODES.CINEMA)?[_vm._v(" Switch to Normal mode ")]:_vm._e()],2)]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","open-delay":2000},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"tile":"","disabled":!_vm.previousVideo},on:{"click":_vm.playPrevious}},on),[_c('v-icon',[_vm._v("mdi-skip-previous")])],1)]}}])},[_c('span',[_vm._v("Play Previous Video")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":2000},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"tile":"","disabled":!_vm.nextVideo},on:{"click":_vm.playNext}},on),[_c('v-icon',[_vm._v("mdi-skip-next")])],1)]}}])},[_c('span',[_vm._v("Play Next Video")])])],1),_c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"tile":""},on:{"click":_vm.markCompleted}},on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}])},[_c('span',[_vm._v("Mark Completed")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }