<template>
  <div class="component container d-flex justify-center align-center h-100">
    <div style="width: 100%; max-width: 500px;">
      <v-card>
        <v-card-text>
          <login-form />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script type="text/babel">
import LoginForm from '@/components/login-form'

export default {
  name: 'views-login-index',
  watch: {},
  props: {},
  data() {
    return {}
  },
  methods: {},
  computed: {},
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {
    LoginForm
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
