<template>
  <div class="component youtube-player">
    <template v-if="!playerReady">
      <div class="spinner d-flex justify-center align-center">
        <div>
          <v-progress-circular indeterminate size="150" />
        </div>
      </div>
    </template>
    <div ref="player" class="player"></div>
    <div class="debug" v-if="debug">
      playerState: {{ playerState }}
    </div>
  </div>
</template>

<script type="text/babel">
export default {
  name: 'components-youtube_player-index',
  watch: {
    playerState: {
      handler(value) {
        if (value === 0) {
          this.$emit('stopped');
        } else if(value === 1) {
          this.$emit('playing');
        } else if(value === 2) {
          this.$emit('paused');
        } else if(value === 3) {
          this.$emit('loading');
        }
      }
    },
    videoId: {
      handler(value) {
        this.loadVideo(value);
      }
    }
  },
  props: {
    videoId: {
      required: true
    }
  },
  data() {
    return {
      debug: false,
      player: undefined,
      playerState: undefined,
      playerReady: false,
    }
  },
  methods: {
    loadVideo(videoId) {
      if(!this.player) {
        console.log('Player not ready');
        return;
      }
      this.player.loadVideoById(videoId);
    },
    onPlayerReady() {
      this.playerReady = true;
    },
    onPlayerStateChange(event) {
      this.playerState = event.data;
    },
    initialize() {
      if (!window.YT) {
        return console.error('YT object not on window');
      }

      let ref = this.$refs['player'];

      this.player = new YT.Player(ref, {
        width: '100%',
        height: '100%',
        videoId: this.videoId,
        playerVars: {
          // 'autoplay': 1,
          'playsinline': 1
        },
        events: {
          'onReady': this.onPlayerReady,
          'onStateChange': this.onPlayerStateChange
        }
      });
    }
  },
  computed: {},
  created() {
  },
  mounted() {
    if (window.YOUTUBE_READY) {
      this.initialize();
    } else {
      window.YOUTUBE_CALLBACKS.push(this.initialize);
    }
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.youtube-player {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  .debug {
    color: white;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
  }

  ::v-deep {
    .spinner,
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

</style>
