<template>
  <div class="component">
    <v-autocomplete
        autofocus
        dark
        hide-details="auto"
        hint="Paste in a YouTube URL. e.g.: https://www.youtube.com/playlist?list=PL34C1F26D03F5F9B8, https://www.youtube.com/@Aurikatariina or https://youtube.com/c/KillTony"
        outlined
        v-model="newValue"
        :items="results"
        item-text="snippet.title"
        return-object
        :loading="isLoading"
        v-on:update:search-input="onSearchInputUpdate"
        color="white"
        hide-no-data
        hide-selected
        no-filter
        v-on:blur="onBlur"
        v-on:input="onSearchPlaylistSelected"
        :search-input="searchQuery"
        placeholder="Search Playlist"
    >
      <template v-slot:append>
        <slot name="append"></slot>
      </template>
      <template v-slot:item="{item}">
        <div class="d-flex" style="width: 500px">
          <div style="width: 100px;">
            <v-img width="100" contain :src="item.snippet.thumbnails.default.url"/>
          </div>
          <div class="flex-grow-1 pa-2">
            <span class="playlist-title">{{ item.snippet.title }}</span>
            <span class="channel-title"> {{ item.snippet.channelTitle }}</span>
            <span class="playlist-description">{{ item.snippet.description }}</span>
          </div>
        </div>
      </template>
    </v-autocomplete>

    {{ playlistList }}

  </div>
</template>

<script type="text/babel">
import Api from "../../clients/api";
import _debounce from 'lodash/debounce'
import _get from "lodash/get";

export default {
  name: 'components-playlist_search-index',
  watch: {},
  props: {
    value: undefined
  },
  data() {
    return {
      results: [],
      searchQuery: '',
      isLoading: false
    }
  },
  methods: {
    onSearchPlaylistSelected(e) {
      let playlistId = _get(e, 'id.playlistId');
      console.log(e);
      this.$emit('list', playlistId);
    },
    onBlur() {
      console.log('blur');
    },
    onSearchInputUpdate(e) {
      this.searchQuery = e;
      this.determineSearchInputType();

      // this.retrieveDebounced();
    },
    determineSearchInputType() {
      if (this.playlistList) {
        this.$emit('list', this.playlistList);
      } else if (this.playlistChannel) {
        this.$emit('channel', this.playlistChannel);
      } else {
        // this.retrieveDebounced();
      }
    },
    retrieveDebounced: _debounce(function () {
      this.retrieve();
    }, 1000),
    retrieve() {
      if (!this.searchQuery || this.searchQuery.length === 0) {
        return Promise.resolve();
      }
      this.isLoading = true;
      return Api.playlistSearch(this.searchQuery)
          .then((res) => {
            console.log('res', res);
            this.results = res.data;
          })
          .catch((e) => {

          })
          .finally(() => {
            this.isLoading = false;
          })
    }
  },
  computed: {
    playlistList() {
      return this.urlParameters['list'];
    },
    playlistChannel() {
      if (!this.searchQuery || typeof this.searchQuery !== "string") {
        return;
      }

      let path = this.searchQuery.split('.com/').pop();
      if (!path) {
        return;
      }

      if (path.indexOf('c/') === 0) {
        return path.split('/')[1];
      }

      if (path.indexOf('@') === 0) {
        return path.replace('@', '');
      }

      return;
    },
    urlParameters() {
      let map = {};
      if (!this.searchQuery || typeof this.searchQuery !== "string") {
        return map;
      }

      this.searchQuery.split('?')
          .pop()
          .split('&')
          .forEach((part) => {
            let parts = part.split('=');
            map[parts[0]] = parts[1];
          });

      return map;
    },
    newValue: {
      get() {
        return this.value;
      },
      set(e) {
        console.log('New value', e);
        this.$emit('input', e);
      }
    }
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.playlist-title {
  display: block;
  font-weight: bold;
}

.playlist-description {
  display: block;
  font-size: .8em;

}

.channel-title {
  display: block;
  font-size: .8em;
  font-style: italic;
}
</style>
