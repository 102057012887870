<template>
  <div class="component">
    <template v-for="playlist in playlists">
      <v-card class="mb-2">
        <v-card-text>
          <playlist v-bind:playlist="playlist"/>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script type="text/babel">
import Playlist from './components/playlist'

export default {
  name: 'components-_boilerplate-index',
  watch: {},
  props: {
    playlists: {
      required: true,
      type: Array
    }
  },
  data() {
    return {}
  },
  methods: {},
  computed: {},
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {
    Playlist
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
