var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component"},[_c('div',{staticClass:"d-flex controls"},[_c('div',{staticClass:"flex-grow-1"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"tile":"","small":"","color":"lime darken-3"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-sort")]),(_vm.sortMode === _vm.SORTS.CHRONOLOGICAL)?[_vm._v("Sort By Upload Date")]:(_vm.sortMode === _vm.SORTS.TITLE)?[_vm._v("Sort By Video Title")]:[_vm._v("Original Sequence")]],2)]}}])},[_c('v-list',[_c('v-list-item-group',[_c('v-list-item',{on:{"click":function($event){return _vm.setSort(_vm.SORTS.CHRONOLOGICAL)}}},[_c('v-list-item-title',[_vm._v("Upload Date")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.setSort(_vm.SORTS.TITLE)}}},[_c('v-list-item-title',[_vm._v("Video Title")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.setSort(_vm.SORTS.NONE)}}},[_c('v-list-item-title',[_vm._v("Original Sequence")])],1)],1)],1)],1),(_vm.sortDirection === _vm.SORTS_DIRECTION.DESCENDING)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"small":"","tile":"","color":"lime darken-3"},on:{"click":function($event){return _vm.setSortDirection(_vm.SORTS_DIRECTION.ASCENDING)}}},on),[_c('v-icon',{attrs:{"s":""}},[_vm._v("mdi-sort-ascending")])],1)]}}],null,false,2389793657)},[_c('span',[_vm._v("Sort Ascending")])])]:[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"tile":"","small":""},on:{"click":function($event){return _vm.setSortDirection(_vm.SORTS_DIRECTION.DESCENDING)}}},on),[_c('v-icon',[_vm._v("mdi-sort-descending")])],1)]}}])},[_c('span',[_vm._v("Sort Descending")])])]],2),(_vm.showControls)?_c('div',[_c('v-btn',{attrs:{"tile":"","small":"","disabled":!_vm.previousVideo},on:{"click":_vm.playPrevious}},[_c('v-icon',[_vm._v("mdi-skip-previous")])],1),_c('v-btn',{attrs:{"tile":"","small":"","disabled":!_vm.nextVideo},on:{"click":_vm.playNext}},[_c('v-icon',[_vm._v("mdi-skip-next")])],1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }