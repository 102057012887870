<template>
  <div class="component">

    <v-menu offset-x light>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            dark
            fab
            depressed
            x-small
            v-bind="attrs"
            v-on="on"
        >
          <template v-if="user">
            <v-img width="10" class="rounded-circle" v-bind:src="user.photoURL"/>
          </template>
          <template v-else>
            <v-icon>mdi-account</v-icon>
          </template>
        </v-btn>
      </template>
      <v-list>
        <template v-if="user">
          <v-list-item v-on:click="logout" dense>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-else>
          <v-list-item v-bind:to="loginRoute">
            <v-list-item-content>
              <v-list-item-title>Login</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script type="text/babel">
import firebase from 'firebase/app'
import {mapMutations} from "vuex";
import {mapGetters} from 'vuex'

export default {
  name: 'components-user_bar-index',
  watch: {},
  props: {},
  data() {
    return {}
  },
  methods: {
    logout() {
      firebase
          .auth()
          .signOut()
          .then(() => {
            this.clearUser();
          })
          .catch(error => {
            console.log(error.message);
            this.$router.push({
              name: 'home'
            });
          });
    },
    ...mapMutations(['clearUser'])
  },
  computed: {
    loginRoute() {
      return {
        name: 'login',
        query: {
          redirect: this.$route.path
        }
      }
    },
    ...mapGetters(['user'])
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
