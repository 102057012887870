import firebase from 'firebase/app'

const PATH_USERS = 'users';

const Service = {
    userId() {
        let user = firebase.auth().currentUser;
        return user.uid;
    },
    _addUserId(data) {
        return {
            ...data,
            user: this.userId()
        }
    },
    async saveUserState(state) {
        let path = `${PATH_USERS}/${this.userId()}/state`;
        return this.save(path, state);
    },
    async savePlaylistHistory(playlistHistory) {
        let path = `${PATH_USERS}/${this.userId()}/state/playlists`;
        return this.saveToList(path, playlistHistory);
    },
    async getUserState() {
        let path = `${PATH_USERS}/${this.userId()}/state`;
        return this.get(path);
    },
    async get(path) {
        return firebase.database().ref(path).get();
    },
    async saveToList(path, data) {
        data = this._addUserId(data);
        let ref = firebase.database().ref(path);
        let newRef = ref.push();
        return newRef.set(data)
            .then(() => {
                return newRef;
            })
    },
    async save(path, data) {
        data = this._addUserId(data);
        let ref = firebase.database().ref(path);
        return ref.update(data)
            .then(() => {
                return ref;
            })
    }
};

export default Service;