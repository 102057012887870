<template>
  <div class="component">
    <div class="d-flex">
      <div style="max-width: 20%">
        <v-img v-bind:src="playlist.playlist.snippet.thumbnails.medium.url"/>
      </div>
      <div class="flex-grow-1 pa-2">
        <h6>{{ playlist.playlist.snippet.title }}</h6>
        <p>{{ playlist.playlist.snippet.description }}</p>
        <span class="d-block">{{ playlist.items }} Videos</span>
        <div>
          <ul>
            <template v-for="retrievedAt in retrievedAt">
              <li>{{ moment.duration(moment(retrievedAt).diff(moment())).humanize() }} ago
              </li>
            </template>
          </ul>
        </div>
        <span class="d-block text--secondary">Created at: {{
            date
          }} - {{ moment.duration(date.diff(moment())).humanize() }} ago</span>
        <div v-if="playlist.lastWatched">
          {{ moment.duration(moment(playlist.lastWatched).diff(moment())).humanize() }} ago
        </div>
      </div>
      <div class="d-flex justify-center align-center">
        <v-btn v-bind:to="route">
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import moment from 'moment'

export default {
  name: 'components-playlist_list-playlist-index',
  watch: {},
  props: {
    playlist: {
      required: true
    }
  },
  data() {
    return {
      moment
    }
  },
  methods: {},
  computed: {
    retrievedAt() {
      return this.playlist.retrievedAt.reverse();
    },
    date() {
      return moment(this.playlist.updatedAt);
    },
    route() {
      return {
        name: 'playlist',
        params: {
          playlistId: this.playlist.playlist.id
        }
      }
    }
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
