<template>
  <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSfGZwOQUdA2Thh7i2xi7mqXPyh4fzlHNimiy5v6HrPJv61vTg/viewform?embedded=true"
      width="100%" height="600" frameborder="0" marginheight="0" marginwidth="0">Loading…
  </iframe>
</template>

<script type="text/babel">
export default {
  name: 'components-feedback_form-index',
  watch: {},
  props: {},
  data() {
    return {}
  },
  methods: {},
  computed: {},
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
