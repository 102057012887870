<template>
  <div class="component">
    <h1>Boilerplate View</h1>

    <playlist-search v-model="playlist" />
    {{ playlist }}
  </div>
</template>

<script type="text/babel">
import PlaylistSearch from "../../components/playlist-search/index.vue";
export default {
  name: 'views-_boilerplate-index',
  watch: {},
  props: {},
  data() {
    return {
      playlist: []
    }
  },
  methods: {},
  computed: {},
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {
    PlaylistSearch
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
