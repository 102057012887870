export default {
    firebase: {
        apiKey: "AIzaSyC9KO6aayKE7PSbUJKrjpXWaMRKZsSo160",
        authDomain: "playlisttools-16a1c.firebaseapp.com",
        databaseURL: "https://playlisttools-16a1c-default-rtdb.firebaseio.com",
        projectId: "playlisttools-16a1c",
        storageBucket: "playlisttools-16a1c.appspot.com",
        messagingSenderId: "190234850959",
        appId: "1:190234850959:web:bd8e26f530e513d4a622fa",
        measurementId: "G-PVBVQ4BHGS"
    }
}