<template>
  <div class="component" v-bind:class="classes">
    <template v-if="loading">
      <template v-for="n in 10">
        <v-skeleton-loader
            v-bind:key="`video_item_${n}`"
            class="video-item"
            type="card"
            v-bind:height="200"
        ></v-skeleton-loader>
      </template>
    </template>
    <template v-else>
      <template v-for="(video, index) in videos">
        <v-card class="mb-2 video-thumbnail"
                v-bind:color="focusVideo === video.videoId ? 'secondary lighten-2' : 'secondary'"
                v-bind:elevation="focusVideo === video.videoId ? 5 : 2">
          <v-card-text class="pa-0">
            <video-thumbnail
                v-bind:key="video.videoId"
                v-bind:class="{'focus': focusVideo === video.videoId}"
                v-on:click="() => onClickedThumbnail(video)"
                v-bind:ref="`video_${video.videoId}`"
                v-bind:display="display"
                v-bind:video="video"
            />
          </v-card-text>
        </v-card>
      </template>
    </template>
  </div>
</template>

<script type="text/babel">
import VideoThumbnail from './components/video-thumbnail'

export default {
  name: 'components-video_list-index',
  watch: {
    videos: {
      deep: true,
      handler() {
        setTimeout(() => {
          this.setFocusVideo();
        }, 1);
      }
    },
    focusVideo: {
      deep: true,
      handler() {
        setTimeout(() => {
          this.setFocusVideo();
        }, 1);
      }
    }
  },
  props: {
    focusVideo: undefined,
    loading: undefined,
    videos: undefined,
    display: {
      default() {
        return 'vertical';
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    onClickedThumbnail(video) {
      this.$emit('clicked-thumbnail', video);
    },
    setFocusVideo() {
      if (!this.focusVideo) {
        return;
      }

      let element = this.$refs[`video_${this.focusVideo}`];
      if (!element) {
        console.log('element not found');
        return;
      }

      element = element[0];

      element.$el.scrollIntoView();
    }
  },
  computed: {
    classes() {
      let result = [];

      result.push(this.display);

      return result;
    }
  },
  created() {
  },
  mounted() {
    this.setFocusVideo();
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {
    VideoThumbnail
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.video-thumbnail {

}

.horizontal {
  .video-thumbnail {
    display: inline-block;
    min-width: 400px;
  }
}

</style>
