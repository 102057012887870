<template>
  <div class="component">
    <div class="d-flex controls">
      <div class="flex-grow-1">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                tile
                small
                v-bind="attrs"
                v-on="on"
                class="mr-1"
                color="lime darken-3"
            >
              <v-icon small class="mr-1">mdi-sort</v-icon>
              <template v-if="sortMode === SORTS.CHRONOLOGICAL">Sort By Upload Date</template>
              <template v-else-if="sortMode === SORTS.TITLE">Sort By Video Title</template>
              <template v-else>Original Sequence</template>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group>
              <v-list-item v-on:click="setSort(SORTS.CHRONOLOGICAL)">
                <v-list-item-title>Upload Date</v-list-item-title>
              </v-list-item>
              <v-list-item v-on:click="setSort(SORTS.TITLE)">
                <v-list-item-title>Video Title</v-list-item-title>
              </v-list-item>
              <v-list-item v-on:click="setSort(SORTS.NONE)">
                <v-list-item-title>Original Sequence</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <template v-if="sortDirection === SORTS_DIRECTION.DESCENDING">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on"
                     small
                     tile
                     color="lime darken-3"
                     v-on:click="setSortDirection(SORTS_DIRECTION.ASCENDING)">
                <v-icon s>mdi-sort-ascending</v-icon>
              </v-btn>
            </template>
            <span>Sort Ascending</span>
          </v-tooltip>
        </template>
        <template v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on"
                     tile
                     small
                     v-on:click="setSortDirection(SORTS_DIRECTION.DESCENDING)">
                <v-icon>mdi-sort-descending</v-icon>
              </v-btn>
            </template>
            <span>Sort Descending</span>
          </v-tooltip>

        </template>
      </div>
      <div v-if="showControls">
        <v-btn tile small
               v-on:click="playPrevious" v-bind:disabled="!previousVideo">
          <v-icon>mdi-skip-previous</v-icon>
        </v-btn>

        <v-btn tile small v-on:click="playNext" v-bind:disabled="!nextVideo">
          <v-icon>mdi-skip-next</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import SORTS from "@/constants/SortModes";
import SORTS_DIRECTION from "@/constants/SortDirections";

export default {
  name: 'components-playlist_controls-index',
  watch: {},
  props: {
    showControls: {
      default() {
        return true;
      }
    },
    previousVideo: undefined,
    nextVideo: undefined,
    sortMode: undefined,
    sortDirection: undefined
  },
  data() {
    return {
      SORTS,
      SORTS_DIRECTION
    }
  },
  methods: {
    setSortDirection(direction) {
      this.$emit('setSortDirection', direction);
    },
    setSort(mode) {
      this.$emit('setSort', mode);
    },
    playPrevious() {
      this.$emit('playPrevious');
    },
    playNext() {
      this.$emit('playNext');
    }
  },
  computed: {},
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
