<template>
  <div class="component">
    <template v-if="playlists">
      <div class="d-flex">
        <div>
          <v-select hide-details dense outlined v-bind:items="playlistSortItems" v-model="playlistsSortMode"></v-select>
        </div>
        <div>
          <v-btn v-on:click="toggleOrder">
            <v-icon>{{
                playlistsSort === SORT_DIRECTIONS.ASCENDING ? 'mdi-sort-descending' : 'mdi-sort-ascending'
              }}
            </v-icon>
          </v-btn>
        </div>
      </div>

      <div class="pa-md-3">
        <v-data-table
            :headers="tableHeaders"
            :items="tableItems"
            :options="{
              itemsPerPage: 50
            }"
            class="elevation-1"
        >
          <template v-slot:item.thumbnail="{ item }">
            <v-img width="100" :src="item.thumbnail.url" />
          </template>
          <template v-slot:item.playlist.snippet.title="{ item }">
            <router-link v-bind:to="{
              name: 'playlist',
              params: {
                playlistId: item.playlist.id
              }
            }">{{ item.playlist.snippet.title }}</router-link>
          </template>
        </v-data-table>
<!--        <playlist-list v-bind:playlists="playlistsSorted"-->
<!--                       v-bind:sort="playlistsSort"-->
<!--        ></playlist-list>-->
      </div>
    </template>
    <template v-else-if="loading">
      <div class="h-100 d-flex justify-center align-center">
        <div class="h-100">
          <v-progress-circular size="70" indeterminate></v-progress-circular>
        </div>
      </div>
    </template>
    <template v-else>

    </template>
  </div>
</template>

<script type="text/babel">
import PlaylistList from '@/components/playlist-list'
import ApiClient from '@/clients/api'
import SORT_DIRECTIONS from '@/constants/SortDirections'
import moment from 'moment'
import _get from 'lodash/get'

const SORTS = {
  'CREATED_AT': 'CREATED_AT',
  'LAST_RETRIEVED_AT': 'LAST_RETRIEVED_AT'
};

export default {
  name: 'views-admin-index',
  watch: {},
  props: {},
  data() {
    return {
      SORTS,
      SORT_DIRECTIONS,
      loading: false,
      playlists: undefined,
      playlistsSort: SORT_DIRECTIONS.DESCENDING,
      playlistsSortMode: SORTS.LAST_RETRIEVED_AT
    }
  },
  methods: {
    toggleOrder() {
      this.playlistsSort = this.playlistsSort === SORT_DIRECTIONS.DESCENDING ? SORT_DIRECTIONS.ASCENDING : SORT_DIRECTIONS.DESCENDING;
    },
    retrieve() {
      this.loading = true;

      ApiClient.getAdminPlaylists()
          .then((res) => {
            this.playlists = res.data.map((playlist) => {
              playlist.thumbnail = _get(playlist, 'playlist.snippet.thumbnails.high', {})
              playlist.watchCount = _get(playlist, 'retrievedAt', []).length
              return playlist;
            });
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.loading = false;
          })
    }
  },
  computed: {
    tableHeaders() {
      return [
        {
          text: 'Thumbnail',
          value: 'thumbnail'
        },
        {
          text: 'Title',
          value: 'playlist.snippet.title'
        },
        {
          text: 'Channel Title',
          value: 'playlist.snippet.channelTitle'
        },
        {
          text: 'Watch Count',
          value: 'watchCount'
        },
        {
          text: 'Items',
          value: 'items'
        },
        {
          text: 'Last watched',
          value: 'lastWatched'
        },
      ]
    },
    tableItems() {
      return this.playlists;
    },

    playlistSortItems() {
      return Object.keys(SORTS).map((value) => {
        return value;
      });
    },
    playlistsSorted() {
      let result;

      switch (this.playlistsSortMode) {
        case SORTS.CREATED_AT:
          result = this.playlists.sort((a, b) => {
            let dateA = moment(a.updatedAt);
            let dateB = moment(b.updatedAt);

            if (dateA.isAfter(dateB)) {
              return 1;
            }

            if (dateB.isAfter(dateA)) {
              return -1;
            }

            return 0;
          });
          break;
        case SORTS.LAST_RETRIEVED_AT:
          result = this.playlists.sort((a, b) => {
            let dateA = a.lastWatched ? moment(a.lastWatched) : moment().subtract(10, 'years');
            let dateB = b.lastWatched ? moment(b.lastWatched) : moment().subtract(10, 'years');

            if (dateA.isAfter(dateB)) {
              return 1;
            }

            if (dateB.isAfter(dateA)) {
              return -1;
            }

            return 0;
          });
          break;
      }

      if (this.playlistsSort === SORT_DIRECTIONS.DESCENDING) {
        result = result.reverse();
      }

      return result;
    }
  },
  created() {
  },
  mounted() {
    this.retrieve();
    // this.playlists = [
    //   {
    //     "playlist": {
    //       "snippet": {
    //         "thumbnails": {
    //           "high": {
    //             "width": 480,
    //             "url": "https://i.ytimg.com/vi/4RRC5z4S6tU/hqdefault.jpg",
    //             "height": 360
    //           }
    //         },
    //         "channelTitle": "joe henry",
    //         "title": "Favorites"
    //       },
    //       "id": "FL09HoYGxwLB6sONApOD2-cw"
    //     },
    //     "items": 447,
    //     "retrievedAt": [
    //       "2022-02-06T04:17:11.639Z",
    //       "2022-02-06T04:18:07.805Z",
    //       "2022-02-06T17:57:29.205Z"
    //     ],
    //     "lastWatched": "2022-02-06T17:57:29.205Z"
    //   }
    // ];
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {
    PlaylistList
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
