<template>
  <div class="component d-flex">
    <div>
      <v-btn small tile v-on:click="goBack">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </div>
    <div class="ml-3 flex-grow-1 d-flex flex-column">
      <div>
        <span class="channel-title">{{ playlist.snippet.channelTitle }}: </span>
        <span class="playlist-title">{{ playlist.snippet.title }}</span>
      </div>
      <div>
        <small>Last retrieved: {{ cacheUpdatedAtMoment.format('MM/DD/YYYY hh:mm') }}</small>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import moment from "moment";

export default {
  name: 'components-playlist_header-index',
  watch: {},
  props: {
    cacheUpdatedAt: undefined,
    playlist: {
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    goBack() {
      this.$router.push({
        name: 'home'
      })
    }
  },
  computed: {
    cacheUpdatedAtMoment() {
      return moment(this.cacheUpdatedAt);
    }
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.channel-title {
  font-weight: bold;
}
</style>
