<template>
  <div class="component container d-flex justify-center align-center h-100"
       v-bind:class="{'overflow-hidden': isNavigating}">
    <div style="width: 100%; max-width: 500px;">
      <transition
          name="custom-classes-transition"
          leave-active-class="animate__animated animate__fast animate__fadeOutUp"
          v-on:after-leave="playlistInputTransitionAfter"
      >
        <div v-if="!isNavigating">
          <!--          <v-btn v-on:click="convertStateFromLocalStorage">convertStateFromLocalStorage</v-btn>-->

          <v-card color="indigo darken-3" elevation="2">
            <v-card-subtitle>
              <div class="d-flex">
                <div class="flex-grow-1">
                  <user-bar/>
                </div>
                <div class="d-flex justify-center align-center">
                  <div>
                    <strong class="lime--text">playlist</strong>.tools
                  </div>
                </div>
              </div>
            </v-card-subtitle>
            <v-card-text class="pb-0">
              <p>
                Simply <strong>search by playlist name</strong> or paste in a <strong>YouTube playlist</strong> or
                <strong>YouTube channel</strong> and unlock
                essential missing features including
                <strong>chronological sorting</strong> and
                <strong>tracking viewed videos</strong>.
                <br/>
                <br/>

                Supports <strong>playlists URLs</strong> <i>(e.g.:
                https://www.youtube.com/playlist?list=PL34C1F26D03F5F9B8)</i> or <strong>channel URLs</strong>
                <i>(e.g.: https://youtube.com/c/KillTony)</i> or <i>https://www.youtube.com/@Aurikatariina</i>.
              </p>
              <playlist-search v-on:list="onList"
                               v-on:channel="onChannel"
                               v-bind:disabled="isNavigating"/>


            </v-card-text>
            <v-divider class="mb-0"/>
            <v-card-text class="pb-0">
              <v-alert dense dismissible type="success" color="indigo darken-4">Now supporting search!<br/><small>In
                addition to pasting in URLs you can now search for your channel as well.</small></v-alert>
              <div>
                <p>
                  Used <strong class="text-decoration-underline">for free</strong> by <strong>1000+</strong> users every
                  month.
                </p>
              </div>
            </v-card-text>
            <v-divider class="mb-0"/>
            <div class="d-flex px-3 py-2">
              <div class="flex-grow-1">
                <!--                <v-btn class="ma-0" fab depressed small color="transparent">-->
                <!--                  <v-icon color="lime">mdi-facebook</v-icon>-->
                <!--                </v-btn>-->
                <!--                <v-btn class="ma-0" fab depressed small color="transparent">-->
                <!--                  <v-icon color="lime">mdi-twitter</v-icon>-->
                <!--                </v-btn>-->
              </div>
              <div>
                <v-dialog
                    max-width="600"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        text
                        class="ma-0 mr-1" fab depressed small color="indigo darken-3">
                      <v-icon color="indigo lighten-4">mdi-code-json</v-icon>
                    </v-btn>
                  </template>

                  <v-card min-height="500">
                    <v-card-title>
                      <h2>Change log</h2>
                    </v-card-title>
                    <v-card-text>
                      <changelog/>
                    </v-card-text>
                  </v-card>
                </v-dialog>

                <v-dialog
                    max-width="600"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="ma-0" fab depressed small color="indigo darken-3">
                      <v-icon color="indigo lighten-4">mdi-help</v-icon>
                    </v-btn>
                  </template>

                  <v-card min-height="500">
                    <feedback-form/>
                  </v-card>
                </v-dialog>
              </div>
            </div>
          </v-card>
        </div>
      </transition>
    </div>
  </div>
</template>

<script type="text/babel">
import PlaylistSearch from '@/components/playlist-search'
import UserBar from '@/components/user-bar'
import {mapGetters, mapActions} from "vuex";
import FeedbackForm from '@/components/feedback-form'
import Changelog from "@/components/changelog";
import ApiClient from "@/clients/api";

export default {
  name: 'views-home-index',
  watch: {},
  props: {},
  data() {
    return {
      isNavigating: false,
      listToNavigateTo: undefined
    }
  },
  methods: {
    playlistInputTransitionAfter() {
      this.navigateToPlaylist(this.listToNavigateTo);
    },
    navigateToPlaylist(list, isChannel) {
      this.$router.push({
        name: 'playlist',
        params: {
          playlistId: list
        },
        query: {
          isChannel
        }
      })
    },
    onChannel(channel) {
      return ApiClient.getChannelUploadPlaylistId(channel)
          .then((result) => {
            this.isNavigating = true;
            this.listToNavigateTo = result.data;
          })
          .catch((e) => {
            let error = e;
            if (e.response && e.response.data) {
              error = e.response.data.error;
            }

            alert(error);
          });
    },
    onList(list) {
      this.isNavigating = true;
      this.listToNavigateTo = list;
    },
    ...mapActions(['convertStateFromLocalStorage'])
  },
  computed: {
    ...mapGetters([
      'playlistHistory'
    ])
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {
    FeedbackForm,
    Changelog,
    PlaylistSearch,
    UserBar
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.site-title {
  text-align: right;
  font-size: 1em;
  opacity: .35;
}
</style>
