<template>
  <div class="component">
    <div class="d-flex">
      <div class="flex-grow-1">
        <v-tooltip bottom v-if="allowModeToggle">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-on:click="toggleDisplayMode" tile>
              <v-icon v-if="currentMode === DISPLAY_MODES.NORMAL">mdi-arrow-expand-horizontal</v-icon>
              <v-icon v-if="currentMode === DISPLAY_MODES.CINEMA">mdi-arrow-collapse-horizontal</v-icon>
            </v-btn>
          </template>
          <span>
            <template v-if="currentMode === DISPLAY_MODES.NORMAL">
              Switch to Cinema mode
            </template>
            <template v-if="currentMode === DISPLAY_MODES.CINEMA">
              Switch to Normal mode
            </template>
          </span>
        </v-tooltip>

        <v-tooltip bottom v-bind:open-delay="2000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-on:click="playPrevious" tile v-bind:disabled="!previousVideo">
              <v-icon>mdi-skip-previous</v-icon>
            </v-btn>
          </template>
          <span>Play Previous Video</span>
        </v-tooltip>

        <v-tooltip bottom v-bind:open-delay="2000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-on:click="playNext" tile v-bind:disabled="!nextVideo">
              <v-icon>mdi-skip-next</v-icon>
            </v-btn>
          </template>
          <span>Play Next Video</span>
        </v-tooltip>

      </div>
      <div>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-on:click="markCompleted" tile>
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </template>
          <span>Mark Completed</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import DISPLAY_MODES from '@/constants/DisplayModes'

export default {
  name: 'components-player_controls-index',
  watch: {},
  props: {
    previousVideo: undefined,
    nextVideo: undefined,
    allowModeToggle: undefined,
    currentMode: undefined
  },
  data() {
    return {
      DISPLAY_MODES
    }
  },
  methods: {
    toggleDisplayMode() {
      this.$emit('toggle-display');
    },
    playPrevious() {
      this.$emit('play-previous');
    },
    playNext() {
      this.$emit('play-net');
    },
    markCompleted() {
      this.$emit('mark-completed');
    }
  },
  computed: {},
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  mixins: [],
  components: {}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
