import axios from 'axios'


const root = '/api';

const Client = {
    playlistSearch(query) {
        let path = `${root}/playlist/search`;
        return this.post(path, {
            q: query
        });
    },
    getCacheDetails(cacheId, itemsIndex) {
        let path = `${root}/cache-details/${cacheId}/${itemsIndex}`;
        return this.get(path);
    },
    getChannelUploadPlaylistId(channel) {
        let path = `${root}/upload-playlist-for-channel/${channel}`;
        return this.get(path);
    },
    getPlaylist(playlistId) {
        let path = `${root}/playlist/${playlistId}`;
        return this.get(path);
    },
    getAdminPlaylists() {
        return this.get(`${root}/admin/playlists`)
    },
    get(path, headers = {}) {
        return axios.get(path, {
            headers: headers
        })
    },
    patch(path, body, headers = {}) {
        return axios.patch(path, {
            headers: headers
        })
    },
    post(path, body, headers = {}) {
        return axios.post(path, body, {
            headers: headers
        })
    }
};

export default Client;